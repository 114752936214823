<!--
  PACKAGE_NAME : src/pages/cc/ivr/notice/modal-listen-notice.vue
  FILE_NAME : modal-listen-notice
  AUTHOR : hpmoon
  DATE : 2024-08-01
  DESCRIPTION : 미리듣기 팝업
--> 
/<template>
  <DxPopup
    :show-title="showTitle"
    :title="modal.title"
    :min-width="modal.minWidth"
    :width="modal.width"
    :min-height="modal.minHeight ? modal.minHeight : null"
    :height="modal.height ? modal.height : null"
    :drag-enabled="modal.dragEnabled"
    :resize-enabled="modal.resizeEnabled"
    :show-close-button="modal.showCloseButton"
    :hide-on-outside-click="modal.hideOnOutsideClick"
    :visible="isOpen"
    @hiding="closeModal"
  >
    <template #content>
        <div v-if="error" class="error-message">{{ errorMessage }}</div>
        <audio v-else controls class="audio-player">
          <source :src="noticeFileSrc" type="audio/wav">
          Your browser does not support the audio element.
        </audio>
    </template>
  </DxPopup>
</template>

<script>

import {DxPopup} from "devextreme-vue/popup";

export default {
  components: {
    DxPopup,
  },

  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    noticeFile: {
      type: String,
      required: true
    },
  },

  watch: {

  },
  computed: {
    /** @description: 모달 타이틀 출력 여부 */
    showTitle() {
      return !!this.modal.title;
    },
    /** @description: 공지사항 음성 파일 경로 */
    noticeFileSrc() {
      // TODO : 경로에 따른 처리 필요. 임시로 샘플파일 지정해놓음
      // this.$_getSystemData('ivr_notice_file_path').configValue; // 파일 경로
      // this.noticeFile // 임시생성 wav 파일명
      return `https://download.samplelib.com/wav/${this.noticeFile}`;
    }
  },
  data() {
    return {
      modal: {
        title: this.$_msgContents('CC.WORD.NOTICE', { defaultValue: '공지사항' })
          +' '+this.$_msgContents('COMPONENTS.PRE_LISTENING', { defaultValue: '미리듣기' }),
        minWidth: '300',
        width: '400',
        minHeight: '130',
        //height: '400',
        dragEnabled: true,
        resizeEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
      },
      error: false,
      errorMessage: '',
    }
  },
  methods: {
    /** @description: 모달 닫기 */
    closeModal() {
      this.$emit('closeModal');
    },
    /** @description : 공지사항 음성 파일 존재여부 체크 메서드 */
    async checkNoticeFile() {
      try {
        // TODO : 존재여부 체크 로직 작성 필요
        this.error = false;
      } catch (error) {
        this.error = true;
        this.errorMessage = '공지사항 음성 파일을 찾을 수 없습니다.';
      }
    },

    /** @description : 라이프사이클 creaed시 호출되는 메서드 */
    createdData() {

    },

    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
      this.checkNoticeFile();
    },

    /** @description : 라이프사이클 beforeDestroyed 호출되는 메서드 */
    beforeDestroyedData() {

    },
  },
  created() {
    this.createdData();
  },

  mounted() {
    this.mountedData();
  },

  beforeDestroy() {
    this.beforeDestroyedData();
  },
}
</script>

<style scoped>
.audio-player {
  display: block;
  width: 100%;
}
</style>